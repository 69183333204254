export default function deviceDatatableJson () {
  const items = [
    { sistem: 'all', field: 'so', header: 'Sistema Operativo', disabled: false },
    { sistem: 'Windows', field: 'id_processor', header: 'Id Del Procesador', disabled: false },
    { sistem: 'all', field: 'model', header: 'Modelo', disabled: false },
    { sistem: 'all', field: 'id_user', header: 'Usuario del Dispositivo', disabled: true },
    { sistem: 'all', field: 'id_Rxart', header: 'Rxart Secure', disabled: false },
    { sistem: 'all', field: 'name', header: 'Nombre', disabled: false },
    { sistem: 'all', field: 'type', header: 'tipo de equipo', disabled: false },
    { sistem: 'all', field: 'status_lock', header: 'Estado de bloqueo', disabled: true },
    { sistem: 'all', field: 'serial_number', header: 'Número de Serie', disabled: false },
    { sistem: 'all', field: 'mac', header: 'Mac Addres', disabled: false },
    { sistem: 'Android', field: 'IMEI', header: 'IMEI', disabled: false },
    { sistem: 'Android', field: 'id_device', header: 'Id Dispositivo', disabled: false },
    { sistem: 'all', field: 'identity', header: 'Identificador', disabled: true },
    { sistem: 'all', field: 'last_date', header: 'Última Conexión', disabled: false },
    { sistem: 'all', field: 'ssid', header: 'Identificador de red SSID', disabled: false },
    { sistem: 'all', field: 'gateway', header: ' IP del equipo', disabled: false }
  ]
  return (items)
}
