import React, { useEffect, useState } from 'react'
import HorizontalBar from '../generals/charts/horizontalBar'
import BoxChart from '../generals/boxCharts'
import styles from '../../css/applications/Apps.module.css'
import PropTypes from 'prop-types'
import { getData } from '../../actions/servicesHttp'
import { ProgressSpinner } from 'primereact/progressspinner'

export default function MostUsedApps ({ category, dateFilter, editCategories }) {
  //* estados de componentes
  const [expand, setExpand] = useState(false)
  const [data, setData] = useState([])
  const [loadingAppsUseTime, setLoadingAppsUseTime] = useState(false)
  const [appsUseTime, setAppsUseTime] = useState()

  //* urls de la API
  const [chartAppsUseTimeUrl] = useState('applications/mostUsedApplications')

  //* llamador de datos de API
  const getRequest = async (url, data, set, setLoading) => {
    setLoading(true)
    const response = await getData(url, data)
    // console.log(response, 'linea 23')
    if (response.status === 200) {
      set(response.data)
    } else {
      set({ error: true, code: response.code })
    }
    setLoading(false)
  }

  useEffect(() => {
    const json = {
      groupApp: category === 8 ? null : category !== 9 ? category : 'no',
      dateFilter,
      view: 'applications'
    }

    getRequest(chartAppsUseTimeUrl, JSON.stringify(json), setData, setLoadingAppsUseTime)
  }, [dateFilter, category])

  useEffect(() => {
    if (data?.length > 0) {
      // console.log(data)
      let array = []
      array = data
      if (data?.length > 5) array = expand === false ? array.slice(0, 5) : array.slice(0, 15)
      setAppsUseTime({
        labels: array.map(app => app.name),
        data: array.map(app => (app.totalTimeSeconds / 3600))
      })
    } else {
      setAppsUseTime({
        labels: [],
        data: []
      })
    }
  }, [data, expand])

  return (
        <div className={styles.horizontalBarContainer}>
          <i className={`${expand ? 'fa-solid fa-minimize' : 'fa-solid fa-maximize'} ${styles.expandIcon} ${editCategories ? `${styles.notVisible}` : `${styles.visible}`} ${data?.length <= 5 ? `${styles.notVisible}` : `${styles.visible}`}`} onClick={() => setExpand(!expand)}></i>
          <BoxChart
              width="100%"
              heightBody="80%"
              title="Uso de las Aplicaciones"
              icon="fa-solid fa-shapes"
              className={`${expand ? styles.maximize : styles.minimize}`}
            >
              {loadingAppsUseTime && <ProgressSpinner />}
              {appsUseTime && appsUseTime?.data?.length !== 0 &&
                <HorizontalBar title='Tiempo de uso en horas (hs)' dataChart={appsUseTime?.data} labels={appsUseTime?.labels} />
              }
              {appsUseTime?.data?.length === 0 && <h5>Sin data a mostrar</h5>}
            </BoxChart>
        </div>
  )
}

MostUsedApps.propTypes = {
  category: PropTypes.number,
  dateFilter: PropTypes.number,
  editCategories: PropTypes.bool
}
