import React, { useState, useEffect } from 'react'
import { postData } from '../../../actions/servicesHttp'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import MenuRS from './components/MenuRS'
import ParametersRS from './components/ParametersRS'
import DevicesRS from './components/DevicesRS'
import MessagesRS from './components/MessagesRS'
import ActionsRS from './components/ActionsRS'
import { ProgressSpinner } from 'primereact/progressspinner'
import Swal from 'sweetalert2'
import $ from 'jquery'
import styles from '../../../css/modals/ModalRSDevice.module.css'

export default function ModalRSDevice ({ show, onHide, title, btnError, btnSuccess, data }) {
  // ? urls de la API cuco
  const [rxartSecureUrl] = useState('RxartSecure?endpoint=query_device')
  const [rxartSecureChangeDeviceStatus] = useState('RxartSecure?endpoint=change_device_status')
  const [rxartSecureChangeDeviceData] = useState('RxartSecure?endpoint=change_device_data')

  //* estados de componentes
  const [deviceRxartSecure, setDeviceRxartSecure] = useState()

  const [parametersRS, setParametersRS] = useState({
    deadLine: 90,
    tolerance: 2,
    maximumStarts: 100
  })

  const [messagesRS, setMessagesRS] = useState({
    installUninstallMessage: '',
    warningMessage: '',
    lockMessage: '',
    permission: 'cli'
  })

  const [actionsRS, setActionsRS] = useState({
    statusid: 'NCD',
    info: ''
  })

  const [labelMenu, setLabelMenu] = useState('dispositivos')
  const [serialBiosDevice, setSerialBiosDevice] = useState('')
  const [loading, setLoading] = useState(false)

  //* función para mostrar el popup
  const showPopup = (iconPopup, titlePopup) => {
    Swal.fire({
      position: 'center',
      icon: iconPopup,
      title: titlePopup,
      showConfirmButton: false,
      timer: 2500
    })
  }

  //* llamado de la API cuco
  const postRequestRS = async (url, body, set, setLoading, setBios) => {
    setLoading(true)
    const devicesComponentsContainer = document.getElementById('devicesComponentsContainer')
    const response = await postData(url, body)
    if (response.status === 200) {
      // console.log(response.data)
      // response = { data: { devices: [] } }
      if (response.data?.devices[0]?.deviceid === undefined) {
        onHide()
        showPopup('error', 'Upps... Algo salió mal!')
      } else {
        set(response.data.devices[0])
        setBios(response.data.devices[0].serial)
      }
    } else {
      set({ error: true, code: response.code })
    }
    setLoading(false)
    devicesComponentsContainer.style.display = 'block'
  }

  useEffect(() => {
    if (show && data?.id_Rxart) {
      const body = JSON.stringify({ id_rxart: data.id_Rxart })
      postRequestRS(rxartSecureUrl, body, setDeviceRxartSecure, setLoading, setSerialBiosDevice)
    }
  }, [show, data])

  //* llamados de la API cuco
  const changeDeviceStatus = async (device, set, url, actions, setActions) => {
    // console.log(data, device)
    $('#btnSucessModalRSDevice').prop('disabled', true)
    if (data?.statusid !== 'NCD') {
      const body = JSON.stringify({
        id_rxart: device.deviceid,
        serialBios: device.serial,
        newStatus: actions.statusid,
        info: actions.info
      })

      const response = await postData(url, body)
      if (response.status === 200) {
        // console.log(response.data)
        set({
          ...device,
          statusid: response?.data.device_status[0].statusid
        })
        setActions({
          ...actions,
          statusid: 'NCD'
        })
        showPopup('success', 'Cambio de estado exitoso!')
      } else {
        showPopup('error', 'Error. No se pudo realizar el cambio de estado!')
        console.log({ error: true, code: response.code })
      }
    }
    $('#btnSucessModalRSDevice').prop('disabled', false)
    onHide()
  }

  const changeDeviceData = async (device, url, bios) => {
    $('#btnSucessModalRSDevice').prop('disabled', true)
    const body = JSON.stringify({
      id_rxart: device.deviceid,
      serialBios: device.serial,
      newSerial: device.serial !== bios.trim() ? bios.trim() : false,
      newDescription: device.description.trim()
    })

    const response = await postData(url, body)
    if (response.status === 200) {
      // console.log(response.data)
      showPopup('success', 'Cambios realizados con éxito!')
    } else {
      showPopup('error', 'Error. No se pudo realizar cambios!')
      console.log({ error: true, code: response.code })
    }
    $('#btnSucessModalRSDevice').prop('disabled', false)
    onHide()
  }

  const rxartSecure = (label, device, set, actions, setActions, bios) => {
    if (label === 'acciones') changeDeviceStatus(device, set, rxartSecureChangeDeviceStatus, actions, setActions)
    else if (label === 'dispositivos') changeDeviceData(device, rxartSecureChangeDeviceData, bios)
  }

  const sectionChange = (section) => {
    // console.log(section)
    setLabelMenu(section)
    const parameters = document.getElementById('parametersRS')
    const devices = document.getElementById('devicesRS')
    const messages = document.getElementById('messagesRS')
    const actions = document.getElementById('actionsRS')
    // console.log(parameters, devices, messages, actions)

    if (section === 'parametros') {
      devices.style.display = 'none'
      messages.style.display = 'none'
      actions.style.display = 'none'
      parameters.style.display = 'flex'
    } else if (section === 'dispositivos') {
      parameters.style.display = 'none'
      messages.style.display = 'none'
      actions.style.display = 'none'
      devices.style.display = 'flex'
      devices.style.flexDirection = 'column'
    } else if (section === 'mensajes') {
      parameters.style.display = 'none'
      devices.style.display = 'none'
      actions.style.display = 'none'
      messages.style.display = 'flex'
      messages.style.flexDirection = 'column'
    } else {
      parameters.style.display = 'none'
      devices.style.display = 'none'
      messages.style.display = 'none'
      actions.style.display = 'flex'
    }
  }

  return (
        <Modal
            show={show}
            size="lg"
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '1.5rem' }}>
                <div className={styles.devicesContainer} >
                  {loading && <ProgressSpinner style={{ width: '5rem', height: '5rem' }} strokeWidth="5" />}
                  <div className={styles.devicesComponentsContainer} id='devicesComponentsContainer'>
                    {deviceRxartSecure && <MenuRS setLabelMenu={setLabelMenu} setSectionMenu={sectionChange} />}
                    {deviceRxartSecure && <DevicesRS data={deviceRxartSecure} setData={setDeviceRxartSecure} device={data} bios={serialBiosDevice} setBios={setSerialBiosDevice}/>}
                    {deviceRxartSecure && <ParametersRS parameters={parametersRS} setParameters={setParametersRS}/>}
                    {deviceRxartSecure && <MessagesRS messages={messagesRS} setMessages={setMessagesRS} />}
                    {deviceRxartSecure && <ActionsRS data={data} device={deviceRxartSecure} actions={actionsRS} setActions={setActionsRS} />}
                  </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => rxartSecure(labelMenu, deviceRxartSecure, setDeviceRxartSecure, actionsRS, setActionsRS, serialBiosDevice)} variant="primary" id="btnSucessModalRSDevice" >
                    {btnSuccess}
                </Button>
                <Button onClick={onHide} variant="danger">
                    {btnError}
                </Button>
            </Modal.Footer>
        </Modal>
  )
};

ModalRSDevice.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
  btnError: PropTypes.string,
  btnSuccess: PropTypes.string,
  data: PropTypes.object
}
