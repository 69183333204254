import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag'
import { getData } from '../../../actions/servicesHttp'
import PropTypes from 'prop-types'
import { Button } from 'primereact/button'
import '../../../css/generals/dataTable.css'
import stylesApps from '../../../css/applications/Apps.module.css'
import { secondsToString } from '../charts/utils/DatesFormats'
import { exportCSVApps, exportPdfApps } from './utilsDataTableLazy'

export default function DataTableLazyApps ({ editCategories, setEditCategories, category, reload, setReload, getApp, putApps, appsToPut, categories, dateFilter, editCategoryApplication, exportsCsv, exportsPdf }) {
  const dt = useRef(null)
  const it = useRef(null)

  //* estados de componentes
  const [loading, setLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(10)
  const [apps, setApps] = useState([])
  const [search, setSearch] = useState('')
  const [selectedFilter, setSelectedFilter] = useState('app')
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filter: 'app',
    groupApp: 'no'
  })

  const columns = [
    {
      field: 'app',
      header: 'Nombre'
    },
    {
      field: 'uninstall',
      header: 'Package'
    },
    {
      field: 'totalTimeSeconds',
      header: 'Tiempo de Uso (hs:min:seg)'
    }
  ]

  const onReload = (set) => {
    loadLazyData()
    set(false)
  }

  useEffect(() => {
    if (reload) {
      onReload(setReload)
    }
    // eslint-disable-next-line
    }, [reload]);

  const loadLazyData = async () => {
    setLoading(true)

    const json = {
      groupApp: lazyParams.groupApp === 8
        ? null
        : lazyParams.groupApp !== 9
          ? lazyParams.groupApp
          : 'no',
      search: search === '' || it.current.value === ''
        ? null
        : search,
      dateFilter,
      offset: lazyParams.first,
      limit: lazyParams.rows,
      order: lazyParams.sortField === null || lazyParams.sortOrder === null
        ? ['id', 'ASC']
        : lazyParams.sortOrder === 1
          ? [lazyParams.sortField, 'ASC']
          : [lazyParams.sortField, 'DESC'],
      column: lazyParams.filter
    }

    const response = await getData('applications', JSON.stringify(json))

    if (response.data?.error && response.data?.error === true) {
      setApps([])
      setLoading(false)
    } else {
      const data = response.data?.rows
      if (data && data.length === 0) {
        setApps([])
      } else if (data && data.length !== 0) {
        data.forEach((app) => {
          if (app.app.charCodeAt(0) === 65528 || app.app.charCodeAt(0) === 8291) app.app = 'No Disponible'
          app.groupApp = app.groupApp === null ? 'Sin Asignar' : categories[app.groupApp]?.name
          app.totalTimeSeconds = secondsToString(app.totalTimeSeconds)
        })
        setApps(data)
      }
      setTotalRecords(response.data?.count)
      setLoading(false)
    }
  }

  useEffect(() => {
    loadLazyData()
  }, [lazyParams, category, dateFilter])

  useEffect(() => {
    setLazyParams({
      ...lazyParams,
      groupApp: category
    })
    setSearch('')
  }, [category])

  const onChangeFilterInput = (e) => {
    setSearch(e.target.value)

    if (e.target.value === '') {
      setTimeout(() => {
        loadLazyData()
      }, 1500)
    }
  }

  const onKeyUpInputText = (e) => {
    if (e.key === 'Enter') {
      loadLazyData()
    }
  }

  const onGlobalFilter = () => {
    loadLazyData()
  }

  const onPage = (event) => {
    setLazyParams({
      ...lazyParams,
      page: event.page,
      first: event.first
    })
  }

  const onSort = (event) => {
    setLazyParams({
      ...lazyParams,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    })
  }

  const onFilters = (e) => {
    setSelectedFilter(e.value)
    setLazyParams({
      ...lazyParams,
      filter: e.value
    })
  }

  const renderHeader = () => {
    const dropdownOptions = [{ label: 'Nombre', value: 'app' }, { label: 'Package', value: 'uninstall' }]
    return (
      <div className={`containerHeader ${stylesApps.headerApps}`}>
          <div className={`${stylesApps.buttonExtract}`}>
                {exportsCsv && <Button
                  type="button"
                  icon="pi pi-file"
                  onClick={
                    () => exportCSVApps(lazyParams.groupApp === 8 ? null : lazyParams.groupApp !== 9 ? lazyParams.groupApp : 'no', dateFilter, search === '' || it.current.value === '' ? null : search)
                  }
                  className={`${stylesApps.search}`}
                  data-pr-tooltip="CSV"
                />}
                {exportsPdf && <Button
                  type="button"
                  icon="pi pi-file-pdf"
                  onClick={
                    () => exportPdfApps(lazyParams.groupApp === 8 ? null : lazyParams.groupApp !== 9 ? lazyParams.groupApp : 'no', dateFilter, search === '' || it.current.value === '' ? null : search, columns)
                  }
                  className={`${stylesApps.search}`}
                  data-pr-tooltip="PDF"
                />}
                {editCategoryApplication && <Button
                  variant="dark"
                  className={`${stylesApps.button}`}
                  onClick={editCategories
                    ? () => {
                        putApps(appsToPut)
                        setEditCategories(!editCategories)
                      }
                    : () => setEditCategories(!editCategories)}
                  id="button-update-apps"
                  >
                    {editCategories ? 'Guardar cambios' : 'Editar categorías'}
                  </Button>}
          </div>
          <div className={`${stylesApps.searchBar}`}>
            <div className={`${stylesApps.containerSearchBar}`}>
              <Button
                disabled={editCategories}
                onClick={() => onGlobalFilter()}
                className={`${stylesApps.search}`}
              >
                <i className={'pi pi-search'} />
              </Button>
              <InputText
                disabled={editCategories}
                value={search}
                onChange={(e) => onChangeFilterInput(e)}
                placeholder="Buscar..."
                onKeyUp={(e) => onKeyUpInputText(e)}
                className={`p-input ${stylesApps.inputSearch}`}
                ref={it}
              />
            </div>
              <Dropdown
                value={selectedFilter}
                options={dropdownOptions}
                onChange={onFilters}
                optionLabel="label"
                optionValue="value"
                className={`filterDropdown ${stylesApps.selectFilters}`}
              />
          </div>
      </div>
    )
  }

  const tableHeader = renderHeader()

  const rowsPerPageDropdown = () => {
    const dropdownOptions = [
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '20', value: 20 }
    ]

    const onRowsPerPageChange = (e) => {
      setSelectedRowsPerPage(e.value)
      setLazyParams({
        ...lazyParams,
        rows: e.value
      })
    }

    return (
        <div className="footerDataTable">
          <span
            className="mx-1"
            style={{ color: 'var(--text-color)', userSelect: 'none' }}
          >
            Aplicaciones por página:
          </span>
          <Dropdown
            value={selectedRowsPerPage}
            options={dropdownOptions}
            onChange={onRowsPerPageChange}
            optionLabel="label"
            optionValue="value"
            className="rowsDropdown"
          />
          <p style={{ marginBottom: '0', fontWeight: 'normal' }}>
            {lazyParams.rows > apps.length
              ? `Mostrando ${apps.length} de ${totalRecords}`
              : `Mostrando ${lazyParams.first + 1} a ${
                lazyParams.first + selectedRowsPerPage
            } de ${totalRecords}`}
          </p>
        </div>
    )
  }

  const onRowEditComplete = (e, id) => {
    const _apps = [...apps]
    const appFound = (element) => element.id === id
    const index = _apps.findIndex(appFound)
    _apps[index].groupApp = e.value
    const appToEdit = _apps[index]
    getApp(appToEdit)
    setApps(_apps)
  }

  const categoryEditor = (rowData) => {
    let categoriesToEdit = ['No Permitidas', 'Sistema', 'Educativas', 'No Educativas', 'Productividad', 'Internet', 'Ocio', 'Otras', 'Eliminar Categoría']
    if (rowData?.system === true) categoriesToEdit = ['Sistema', 'Educativas', 'No Educativas', 'Productividad', 'Internet', 'Ocio', 'Otras', 'Eliminar Categoría']
    let category = null
    if (rowData.groupApp !== 'Sin Asignar') {
      category = categoriesToEdit.filter((element) => element === rowData.groupApp)
      category = category[0]
    }

    return (
          <Dropdown
            className='filterDropdown categoriesToEditDropdown'
            value={category}
            options={categoriesToEdit}
            onChange={(e) => onRowEditComplete(e, rowData.id)}
            placeholder="Seleccionar categoría"
          />
    )
  }

  const categoryBodyTemplate = (rowData) => {
    return <Tag value={rowData.groupApp} category={rowData.groupApp} sortable={'groupApp'}></Tag>
  }

  return (
      <div className="datatable-doc-demo">
        <DataTable
            editMode="row"
            size="large"
            className="p-datatable-customers"
            ref={dt}
            value={apps}
            lazy
            dataKey="id"
            paginator
            onPage={onPage}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            rows={lazyParams.rows}
            first={lazyParams.first}
            responsiveLayout="scroll"
            totalRecords={totalRecords}
            loading={loading}
            emptyMessage="No existen datos para mostrar."
            header={tableHeader}
            footer={rowsPerPageDropdown}
        >
          {columns.map((column) => {
            return (
              <Column
                key={column.field}
                field={column.field}
                header={column.header}
                sortable={column.field !== 'totalTimeSeconds'}
                style={{ textAlign: 'center' }}
              />
            )
          })}
          {editCategories
            ? (<Column
            header="Categoría"
            style={{ textAlign: 'center' }}
            body={categoryEditor}
          />)
            : (<Column
            key='groupApp'
            field='groupApp'
            header="Categoría"
            sortable='groupApp'
            style={{ textAlign: 'center' }}
            body={categoryBodyTemplate}
          />)}
        </DataTable>
      </div>
  )
}

DataTableLazyApps.propTypes = {
  editCategories: PropTypes.bool,
  setEditCategories: PropTypes.func,
  category: PropTypes.number,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  getApp: PropTypes.func,
  categories: PropTypes.array,
  editCategoryApplication: PropTypes.bool,
  putApps: PropTypes.func,
  appsToPut: PropTypes.array,
  dateFilter: PropTypes.number,
  exportsCsv: PropTypes.bool,
  exportsPdf: PropTypes.bool
}
