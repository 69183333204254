/* eslint-disable react/style-prop-object */
import React, { useState, useRef, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Logo from '../svg/LogoMdm'
import ModalLogin from '../modals/modalLogin'
import styles from '../../css/index/Header.module.css'

export default function Header () {
  const [show, setShow] = useState(false)
  const modalRef = useRef(null)

  const handleClickOutside = ({ target }) => {
    if (modalRef.current && !modalRef.current.contains(target)) {
      setShow(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <header className={styles.headerMain}>
      <div>
        {/* <img src={Logo} alt="logo" /> */}
        <Logo />
      </div>
      <nav className={styles.headerLinks}>
        <ul>
          <li>
            <a href="/">Inicio</a>
          </li>
          <li>
            <a
              href="https://pixartargentina.com.ar/soluciones.html"
              target="_blank"
              rel="noreferrer"
            >
              Soluciones
            </a>
          </li>
          <li>
            <a
              href="https://pixartargentina.com.ar/galeria.html"
              target="_blank"
              rel="noreferrer"
            >
              Empresa
            </a>
          </li>
          <li>
            <a
              href="https://pixart.mercadoshops.com.ar"
              target="_blank"
              rel="noreferrer"
            >
              Stock
            </a>
          </li>
        </ul>
      </nav>
      <div className={styles.headerContacts}>
        <div>
          <h6>Telefono:</h6>
          <a href="tel:+541143005900" target="_blank" rel="noreferrer">
            <span>(+54)11 4300-5900</span>
          </a>
        </div>
        <div>
          <h6>Email:</h6>
          <a
            href="mailto:info@pixartargentina.com.ar"
            target="_blank"
            rel="noreferrer"
          >
            <span>info@pixartargentina.com.ar</span>
          </a>
        </div>
      </div>
      <div className={styles.btn}>
        <Button
          onClick={() => (!show ? setShow(true) : setShow(false))}
          className="boton"
          variant="primary"
        >
          <b>Acceder</b>
        </Button>
      </div>
      <div
        className={styles.modal}
        style={{
          display: show && show === true ? 'flex' : 'none'
        }}
        ref={modalRef}
      >
        <ModalLogin />
      </div>
    </header>
  )
}
